
let shoppingMall = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * ��ȡ������Ϣ
     * @param {any} success
     * @param {any} error
     */

    this.getAssetsDetails = function (organitionId,date,type,pageindex,pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/AssetsDetails';
        if (organitionId) {
			url += `/${organitionId}`;
		}
		if (date) {
			url += `/${date}`;
		}
		if (type) {
            url += `/${type}`;
		}
        if (pageindex) {
            url += `/${pageindex}`;
		}
        if (pageSize) {
            url += `/${pageSize}`;
		}
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getIsVerify = function(phone, code,success, error) {
        var url = ServiceBaseAddress + '/api/VerificationCode/IsVerify/' + phone + '/' + code; 
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    // 充值
    this.Recharge = function (item, success, error) {
        // var url = ServiceBaseAddress + '/api/Distributor/SubmitRechargeNative'
        var url = ServiceBaseAddress + '/api/WeChatPay/SubmitNative'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.VerificationSuccessPay = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/VerificationSuccessPay'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.WeChatPayVerificationSuccessPay = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/WeChatPay/VerificationSuccessPay'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    this.getSetUserPassword= function(phone, code,success, error) {
        var url = ServiceBaseAddress + '/api/User/SetUserPassword?userId=' + phone + '&password=' + code; 
        TokenClient.Put(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getPayPassword= function(item,success, error) {
        var url = ServiceBaseAddress + '/api/User/PayPassword' 
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    
    this.getSendVerifyCode = function(id, success, error) {
        var url = ServiceBaseAddress + '/api/VerificationCode/SendVerifyCode/' + id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getUserDistributor = function (success, error) {
        var url = ServiceBaseAddress + '/api/User/UserDistributor';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getReceivingAddress = function (success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getReceivingAddressSearch = function (keyWord,success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress/' + keyWord;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.deleteReceivingAddress = function (id,success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress/' + id;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.deltailReceivingAddress = function (id,success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress/Detail/' + id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.putReceivingAddress = function (param,success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    this.getResultDrugs = function (param,success, error) {
        
        var url = ServiceBaseAddress + '/api/SelfDiagnosisSyndrome/Drugs';
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.ZgetResultDrugs = function (param,success, error) {
       
        var url = ServiceBaseAddress + '/api/Questionnaire/Result/Drugs';
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.addReceivingAddress = function (param,success, error) {
        var url = ServiceBaseAddress + '/api/ReceivingAddress';
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Regions = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        if (regionId) {
            url += '/' + regionId;
        }
        url += '/Childrens';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getRegions = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Detial = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��������
     * @param {any} regionName ��������
     * @param {any} fullName ����ȫ��
     * @param {any} regionCode �������
     * @param {any} postCode ������������
     * @param {any} regionLevel ���򼶱� 1��ʡ  2����  3������  4�����򡢽ֵ�  5������
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (regionName, fullName, regionCode, postCode, regionLevel, parentId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        var param = {
            regionName: regionName,
            fullName: fullName,
            regionCode: regionCode,
            postCode: postCode,
            regionLevel: regionLevel,
            parentId: parentId
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �༭������Ϣ
     * @param {any} regionId
     * @param {any} regionName ��������
     * @param {any} fullName ����ȫ��
     * @param {any} regionCode �������
     * @param {any} postCode ������������
     * @param {any} regionLevel ���򼶱� 1��ʡ  2����  3������  4�����򡢽ֵ�  5������
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (regionId, regionName, fullName, regionCode, postCode, regionLevel, parentId, success, error) {
        var url = ServiceBaseAddress + '/api/Region';
        var param = {
            id: regionId,
            regionName: regionName,
            fullName: fullName,
            regionCode: regionCode,
            postCode: postCode,
            regionLevel: regionLevel,
            parentId: parentId
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �Ƴ�����
     * @param {any} regionId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (regionId, success, error) {
        var url = ServiceBaseAddress + '/api/Region/' + regionId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { shoppingMall }